.bg-image:after {
    position: absolute;
    background: #00000045;
    content: '';
    top: 0px;
    width: 100%;
    height: 100%;
    left: 0;
}

@media screen and (max-width:767px) {
    .allResourceBox.grp-card-head {
        height: auto;
    }
    .allResourceBox .grp-btn {
        position: initial;
        margin-top: 12px;
    }
    .table_search.resourceSearch form.searchbox input.text.search-input {
        width: 100%;
    }
    .table_search.resourceSearch form.searchbox {
        width: 100%;
    }
    .allResourceBox {
        flex-direction: column;
    }
    .grp-card-head .grp-text {
        font-size: 1rem;
    }
    .content-page.resourcesTxt p.text-center {
        font-size: 15px!important;
        line-height: 18px!important;
    }
    .resourcesBoxItem {
        height: auto!important;
    }
}