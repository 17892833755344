.sign-in-detail {
	color: $white;
	// padding: 12.25rem 1rem 0rem 5rem;
	height: 100vh;
}

.sign-in-page {
	height: 100vh;
	// background: var(--bs-primary);
	background: linear-gradient(rgba(0, 0, 0, 0.4),
			rgba(0, 0, 0, 0.4)), url("https://portal.pasecondarytransition.com/static/media/slider3.e2428352dbcba1cb9625.jpg");
	// background: -moz-linear-gradient(-45deg, rgba(var(--bs-primary-rgb),.8) 0%,  100%);
	// background: -webkit-linear-gradient(-45deg, rgba(var(--bs-primary-rgb),.8) 0%,  100%);
	// // background: linear-gradient(135deg, rgba(var(--bs-primary-rgb),.8) 0%, var(--bs-primary) 100%);
	// filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(var(--bs-primary-rgb),.8)', endColorstr='var(--bs-primary)', GradientType=1);
	position: relative;
	overflow: auto;
	background-repeat: no-repeat;
	background-size: cover;

	.container {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

.sign-in-logo {
	display: table-cell;
	width: 100%;

	img {
		height: 4.125rem;
	}
}

.sign-in-from {
	padding: 0 3.75rem;
}

.sign-info {
	//border-top: 1px solid $border-color;
	// margin-top: 1.875rem;
	padding-top: 1rem;
}

.iq-social-media {
	margin: 0;
	padding: 0;
	float: right;

	li {
		list-style: none;
		float: left;
		margin-right: 0.625rem;

		&:last-child {
			margin-right: 0;
		}

		a {
			height: 1.875rem;
			width: 1.875rem;
			text-align: center;
			font-size: 1.125rem;
			line-height: 1.875rem;
			display: inline-block;
			background: rgba(var(--bs-primary-rgb), .2);
			;

			// color: var(--bs-primary) important;
			@if $enable-rounded ==true {
				@include radius-mixin($border-radius);
			}

			&:hover {
				text-decoration: none;
			}
		}
	}
}

#container-inside {
	position: relative;
	height: 100vh;
	width: 100vw;
	margin: 0px auto;
	padding: 0px auto;
	overflow: visible;
}

#circle-small {
	-webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
	animation: circle-small-scale 3s ease-in-out infinite alternate;
	animation-timing-function: cubic-bezier(.6, 0, .4, 1);
	animation-delay: 0s;
	position: absolute;
	top: 12.5rem;
	left: -9.375rem;
	background: $white;
	width: 18.75rem;
	height: 18.75rem;

	@if $enable-rounded ==true {
		@include radius-mixin($border-radius-pill);
	}

	opacity: 0.4;
}

#circle-medium {
	-webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
	animation: circle-small-scale 3s ease-in-out infinite alternate;
	animation-timing-function: cubic-bezier(.6, 0, .4, 1);
	animation-delay: 0.3s;
	position: absolute;
	top: 3.125rem;
	left: -18.75rem;
	background: $white;
	width: 37.5rem;
	height: 37.5rem;

	@if $enable-rounded ==true {
		@include radius-mixin($border-radius-pill);
	}

	opacity: 0.3;
}

#circle-large {
	-webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
	animation: circle-small-scale 3s ease-in-out infinite alternate;
	animation-timing-function: cubic-bezier(.6, 0, .4, 1);
	animation-delay: 0.6s;
	position: absolute;
	top: -6.25rem;
	left: -28.125rem;
	background: $white;
	width: 56.25rem;
	height: 56.25rem;

	@if $enable-rounded ==true {
		@include radius-mixin($border-radius-pill);
	}

	opacity: 0.2;
}

#circle-xlarge {
	-webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
	animation: circle-small-scale 3s ease-in-out infinite alternate;
	animation-timing-function: cubic-bezier(.6, 0, .4, 1);
	animation-delay: 0.9s;
	position: absolute;
	top: -15.625rem;
	left: -37.5rem;
	background: $white;
	width: 75rem;
	height: 75rem;

	@if $enable-rounded ==true {
		@include radius-mixin($border-radius-pill);
	}

	opacity: 0.1;
}

#circle-xxlarge {
	-webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
	animation: circle-small-scale 3s ease-in-out infinite alternate;
	animation-timing-function: cubic-bezier(.6, 0, .4, 1);
	animation-delay: 1.2s;
	position: absolute;
	top: -25rem;
	left: -46.875rem;
	background: $white;
	width: 93.75rem;
	height: 93.75rem;

	@if $enable-rounded ==true {
		@include radius-mixin($border-radius-pill);
	}

	opacity: 0.05;
}

@include media-breakpoint-down(lg) {
	.sign-in-page {
		overflow: auto;
	}

	.sign-in-detail {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}
}

@include media-breakpoint-down(md) {
	.sign-in-detail {
		// padding: 3.125rem 1.25rem;
	}
}

@-webkit-keyframes circle-small-scale {
	0% {
		-webkit-transform: scale(1.0);
	}

	100% {
		-webkit-transform: scale(1.1);
	}
}

@keyframes circle-small-scale {
	0% {
		transform: scale(1.0);
	}

	100% {
		transform: scale(1.1);
	}
}

@media only screen and (min-width: 281px) and (max-width:321px){
	.sign-in-from {
		padding: 0 3.25rem;
	}
}

@media only screen and (min-width: 768px) {
	.main-div {
		position: fixed;
	}
}

@media only screen and (min-width: 0px) and (max-width: 280px) {
	.sign-in-from {
		padding: 0px 1rem;
	}
}

// @media only screen and (min-width: 1280px) and (min-height: 800px) {
// 	.sign-in-detail {
// 		color: $white;
// 		// padding: 20rem 1rem 0rem 9rem;
// 		height: 100vh;
// 	}
// }

// @media only screen and (min-width: 1366px) and (max-width: 1440px) {
// 	.sign-in-detail {
// 		color: $white;
// 		// padding: 20rem 1rem 0rem 9rem;
// 		height: 100vh;
// 	}
// }

// @media only screen and (min-width: 1440px) and (max-width: 1920px) {
// 	.sign-in-detail {
// 		color: $white;
// 		// padding: 25rem 1rem 0rem 9rem;
// 		height: 100vh;
// 	}
// }

@media only screen and (min-width: 1920px) and (max-width: 2160px) {
	.sign-in-detail {
		color: $white;
		// padding: 30rem 1rem 0rem 21rem;
		height: 95.6vh;
	}
}

@media only screen and (min-width: 2160px) {
	.sign-in-detail {
		color: $white;
		// padding: 28rem 1rem 0rem 25rem;
		height: 100vh;
	}
}

// @media only screen and (min-width: 2304px) and (max-width: 2560px) {
// 	.sign-in-detail {
// 		color: $white;
// 		padding: 42rem 1rem 0rem 28rem;
// 		height: 100vh;
// 	}
// }

// @media only screen and (min-width: 2560px) and (max-width: 2880px) {
// 	.sign-in-detail {
// 		color: $white;
// 		padding: 42rem 1rem 0rem 33rem;
// 		height: 100vh;
// 	}
// }

// @media only screen and (min-height: 1600px) and (max-height: 1699px) {
// 	.sign-in-detail {
// 		color: $white;
// 		padding: 47rem 1rem 0rem 33rem;
// 		height: 100vh;
// 	}
// }

// @media only screen and (min-height: 1700px) {
// 	.sign-in-detail {
// 		color: $white;
// 		padding: 48rem 1rem 0rem 33rem;
// 		height: 100vh;
// 	}
// }

// @media only screen and (min-width: 2880px) and (max-width: 3840px) {
// 	.sign-in-detail {
// 		color: $white;
// 		padding: 49rem 1rem 0rem 42rem;
// 		height: 100vh;
// 	}
// }

// @media only screen and (min-width: 3840px) and (max-width: 4096px) {
// 	.sign-in-detail {
// 		color: $white;
// 		padding: 56rem 1rem 0rem 58rem;
// 		height: 100vh;
// 	}
// }


// @media only screen and (min-width: 4096px) and (max-width: 5120px) {
// 	.sign-in-detail {
// 		color: $white;
// 		padding: 65rem 1rem 0rem 64rem;
// 		height: 100vh;
// 	}
// }

// @media only screen and (min-width: 5120px) {
// 	.sign-in-detail {
// 		color: $white;
// 		padding: 81rem 1rem 0rem 88rem;
// 		height: 100vh;
// 	}
// }