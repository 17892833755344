// a{
//     color: #173F5F;
//     &:hover{
//         color: var(--#{$variable-prefix}primary-shade-20);
//     }
// }

a {
    color: #173f5f;

    &:hover {
        color: #0e1011;
    }
}

@media only screen and (max-width: 414px) {
    .banner-img {
        width: 100px;
        height: auto;
    }
}