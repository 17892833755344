.main-header {
    background: white;
    padding: 20px 20px;
    border-radius: 5px;
    box-shadow: 1px 1px 10px #dbdbdb
}

.inner-header {
    width: 100%;
}

.inner-header h2 {
    color: #173f5f;
    font-weight: bolder;
    text-align: center;
    width: 61%
}

.inner-header a {
    width: 39%;
}

.main-header p {
    font-size: 20px;
    color: #173f5f;
    font-weight: bolder;
    text-align: center;
}

.assessment-area {
    font-size: 16px;
}

.assessment {
    font-size: 16px;
}

.search-btn {
    width: 100%;
}

.assessment-text {
    color: #20639A;
    font-size: 1.1rem;
    text-align: center;
    font-weight: 600
}

.learn-more a {
    width: 60%;
}

.learn-more button {
    font-size: .75rem;
    font-weight: bolder;
    background: #3caea3;
    color: #fff;
    padding: 0px 5px;
    border-radius: 24px;
    text-align: center;
    width: 100%;
    border: none;
}

.heart-icon{
    font-size: 25px;
    color: #20639A;
    position: absolute;
    left: 92%;
    bottom: 0px
}

.header-title{
    position: relative;
}

@media  (max-width:420px) {
    .heart-icon{
        font-size: 20px;
        color: #20639A;
        position: absolute;
        left: 80%;
        bottom: 0px
    }


    .assessment-container label b{
        font-size: 14px;
    }

    .assessment-area-container label b{
        font-size: 14px;
    }

    .inner-header a{
        width: 39%;
    }
    
}

@media  (max-width:767px) {
    .assesmentTable tbody tr td ul {
        margin: 0px;
        padding: 0px 16px;
    }
    .assesmentTable tbody tr td a, .assesmentTable tbody tr td, .assesmentTable tbody tr td ul li {
        font-size: 14px!important;
        line-height: 18px;
    }
    .assesmentTable table.table thead tr th {
        font-size: 15px!important;
        line-height: 18px;
    }
    .assesmentSearch {
        flex-direction: column;
    }
    .main-header.assessmentsHeader .inner-header a .btn {
        font-size: 12px;
    }
    .main-header.assessmentsHeader {
        padding: 20px 10px;
    }
    .main-header.assessmentsHeader .inner-header h2 {
        font-size: 20px;
    }
}