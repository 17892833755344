.iq-fslightbox-img{
    a{
        img{
            &.img-fluid{
                min-width: 8em;
                max-width: 30em;
                width: 100%;
                height: 100%;
            }
        }
    }
}
