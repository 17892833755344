// userlistTable css
.table_heading_text{
    color: #173F5F;
}
.table_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: flex-end;
    width: 100% ;
    margin-left: 10px;
    // overflow: auto;
}
.table_container > div {
    width:100%;
    // padding-left: 30px;
    overflow: auto;
}
.phone{
    border: none;
}
.edit{
    color: #ff1744;
    cursor: pointer

}
.heading_bg{
    background-color: #173F5F;
}
.img_name_div{
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: start;
}
.tbl_name{
    display: flex;
    flex-direction: row;
}
.table_search {
	.searchbox {
		width: 15rem;
		position: relative;
		.search-link {
			position: absolute;
			right: 0.6rem;
			top: 0.5rem;
			display: flex;
		}
	}
}
/* index.scss */
.notification-list a {
    color: inherit; // Inherit color from parent, which ensures it starts with the same color as the text
    text-decoration: none; // Remove underline by default
  }
  
  .notification-list a:hover {
    color: blue; // Change color to blue on hover
  }
  

// groups css

.group_heading_text{
    padding-left: 21%;
}
.create_group_text{
    padding-right: 21%;
}
.group_heading_sec{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px 20px 0px;
}
// .sticky-container {
//     position: -webkit-sticky;
//     position: sticky;
//     top: 10px; // Adjust this value based on your preference
//   }
  
//   .card-container {
//     margin-bottom: 20px;
//   }
  
//   .notification-list {
//     list-style-type: none;
//     padding: 0;
//     margin: 0;
//   }
  
//   .grp-text {
//     font-weight: bold;
//   }
  
//   li {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     margin-bottom: 10px;
//   }
//   .event-card {
//     background-color: grey;
//     padding: 20px;
//     margin: 10px 0;
//     border-radius: 5px;
//     color: white;
// }
  
//   

// .btn-col{
//     background-color: #35aba0 !important;
//     border: none !important;
// }

// @media only screen and (max-width: 414px) {
// 	.table_search{
//         width:10rem;
        
//     }
//     .searchbox{
//         width: 10rem;
//     }

//     .table_search .searchbox .search-link {
//         position: absolute;
//         right: 5.1rem;
//         top: 0.5rem;
//         display: flex;
//     }
// }


// @media only screen and (min-width: 1200px) {
// 	.table_search{
//         width:10rem;
        
//     }
//     .searchbox{
//         width: 10rem;
//     }

//     .table_search .searchbox .search-link {
//         position: absolute;
//         right: 5.1rem;
//         top: 0.5rem;
//         display: flex;
//     }
// }

@media only screen and (min-width: 280px) and (max-width: 320px){
    .table_search {
        .searchbox {
            width: 5rem;
            position: relative;
            .search-link {
                position: absolute;
                right: 0rem;
                top: 0.5rem;
                display: flex;
            }
        }
    }

    .table_heading_text {
        font-size: 1rem;
        margin-top: 8px;
    }
    
}

@media only screen and (min-width: 320px) and (max-width: 360px){
    .table_search {
        .searchbox {
            width: 7rem;
            position: relative;
            .search-link {
                position: absolute;
                right: 0rem;
                top: 0.5rem;
                display: flex;
            }
        }
    }

    .table_heading_text {
        font-size: 1rem;
        margin-top: 8px;
    }
    
}

@media only screen and (min-width: 360px) and (max-width: 375px){
    .table_search {
        .searchbox {
            width: 10rem;
            position: relative;
            .search-link {
                position: absolute;
                right: 0.1rem;
                top: 0.5rem;
                display: flex;
            }
        }
    }

    .table_heading_text {
        font-size: 1rem;
        margin-top: 8px;
    }
    
}

@media only screen and (min-width: 375px) and (max-width: 390px){
    .table_search {
        .searchbox {
            width: 10rem;
            position: relative;
            .search-link {
                position: absolute;
                right: 0.1rem;
                top: 0.5rem;
                display: flex;
            }
        }
    }
    
}

@media only screen and (min-width: 390px) and (max-width: 393px){
    .table_search {
        .searchbox {
            width: 10rem;
            position: relative;
            .search-link {
                position: absolute;
                right: 0.1rem;
                top: 0.5rem;
                display: flex;
            }
        }
    }
    
}
 
@media only screen and (min-width: 393px) and (max-width: 414px){
    .table_search {
        .searchbox {
            width: 10rem;
            position: relative;
            .search-link {
                position: absolute;
                right: 0.1rem;
                top: 0.5rem;
                display: flex;
            }
        }
    }
    
}

@media only screen and (min-width: 414px) and (max-width: 480px){
    .table_search {
        .searchbox {
            width: 10rem;
            position: relative;
            .search-link {
                position: absolute;
                right: 0.1rem;
                top: 0.5rem;
                display: flex;
            }
        }
    }
    
}

// .eventsCard{
//     // background: red !important;
//     display: flex;
//     flex-direction: row;

//     @media only screen and (min-width: 414px) and (max-width: 480px){
//         .card-container 
//         {
//         flex-direction: column ;
//         }
        
//     }
    
// }




@media only screen and (min-width: 1200px){
    .table_search {
        .searchbox {
            width: 30rem;
            position: relative;
            .search-link {
                position: absolute;
                right: 0.6rem;
                top: 0.5rem;
                display: flex;
            }
        }
    }

    
}

select{
font-family: fontAwesome
}


a h6:hover{
    color:#1E70BF;
    text-decoration: none;
}

.events-wrapper {
    background-color: white;
  }
  
  .events-row {
    overflow: hidden;
    height: 85vh;
  }
  
  .events-col {
    height: 100%;
    overflow-y: scroll;
  }
  
  .events-div {
    height: 100%;
  }
  
  .card-container {
    margin-bottom: 20px;
  }
  
  .event-card {
    background-color: grey;
    padding: 20px;
    margin: 10px 0;
    border-radius: 5px;
    color: white;
  }
  
  .grp-text {
    font-weight: bold;
  }
  
  .highlight-bar {
    background-color: #3caea3;
    height: 3px;
    width: 50px;
    margin-left: 0.7rem;
  }
  
  .notification-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .notification-list a {
    color: inherit;
    text-decoration: none;
  }
  
  .notification-list a:hover {
    color: blue;
  }
  
  a h6:hover {
    color: #1E70BF;
    text-decoration: none;
  }
  
  @media (max-width: 767.98px) {
    .events-row {
      height: auto;
    }
  
    .events-col {
      height: auto;
    }
  
   
  }
  
  @media (min-width: 768px) and (max-width: 1199.98px) {
    .events-row {
      height: 85vh;
    }
}
  
    // .events-col {
    //   height: 100%;
    // }
  
    
    .eventsConatiner{
        margin: 0px;
        padding: 0px;
    }