// Utility generator
// Used to generate utilities & print utilities
@mixin generate-utility($utility, $infix, $is-rfs-media-query: false) {
  $values: map-get($utility, values);

  // If the values are a list or string, convert it into a map
  @if type-of($values)=="string" or type-of(nth($values, 1)) !="list" {
    $values: zip($values, $values);
  }

  @each $key,
  $value in $values {
    $properties: map-get($utility, property);

    // Multiple properties are possible, for example with vertical or horizontal margins or paddings
    @if type-of($properties)=="string" {
      $properties: append((), $properties);
    }

    // Use custom class if present
    $property-class: if(map-has-key($utility, class), map-get($utility, class), nth($properties, 1));
    $property-class: if($property-class ==null, "", $property-class);

    // State params to generate pseudo-classes
    $state: if(map-has-key($utility, state), map-get($utility, state), ());

    $infix: if($property-class =="" and str-slice($infix, 1, 1)=="-", str-slice($infix, 2), $infix);

    // Don't prefix if value key is null (eg. with shadow class)
    $property-class-modifier: if($key, if($property-class =="" and $infix =="", "", "-") + $key, "");

    @if map-get($utility, rfs) {

      // Inside the media query
      @if $is-rfs-media-query {
        $val: rfs-value($value);

        // Do not render anything if fluid and non fluid values are the same
        $value: if($val ==rfs-fluid-value($value), null, $val);
      }

      @else {
        $value: rfs-fluid-value($value);
      }
    }

    $is-css-var: map-get($utility, css-var);
    $is-local-vars: map-get($utility, local-vars);
    $is-rtl: map-get($utility, rtl);

    @if $value  !=null {
      @if $is-rtl ==false {
        /* rtl:begin:remove */
      }

      @if $is-css-var {
        .#{$property-class + $infix + $property-class-modifier} {
          --#{$variable-prefix}#{$property-class}: #{$value};
        }

        @each $pseudo in $state {
          .#{$property-class + $infix + $property-class-modifier}-#{$pseudo}:#{$pseudo} {
            --#{$variable-prefix}#{$property-class}: #{$value};
          }
        }
      }

      @else {
        .#{$property-class + $infix + $property-class-modifier} {
          @each $property in $properties {
            @if $is-local-vars {

              @each $local-var,
              $value in $is-local-vars {
                --#{$variable-prefix}#{$local-var}: #{$value};
              }
            }

            #{$property}: $value if($enable-important-utilities,  !important, null);
          }
        }

        @each $pseudo in $state {
          .#{$property-class + $infix + $property-class-modifier}-#{$pseudo}:#{$pseudo} {
            @each $property in $properties {
              #{$property}: $value if($enable-important-utilities,  !important, null);
            }
          }
        }
      }

      @if $is-rtl ==false {
        /* rtl:end:remove */
      }
    }
  }
}

// ======== media-query-for-mobile-screens =======
@media only screen and (min-width: 0px) and (max-width: 288px) {
  .sign-in {
    display: none;
  }

  .sign-in-bg {
    height: 100vh;
    padding-top: 4vh;
  }

  .sign-up {
    display: none;
  }

  .sign-up-bg {
    padding-bottom: 3rem;
  }

  .forgot {
    display: none;
  }

  .forgot-bg {
    height: 100vh;
    padding-top: 12vh;
  }

  .recover {
    display: none;
  }
  .confirm{
    display: none;
  }
  .confirm-bg{
    height: 100vh;
    padding-top: 12vh;
  }
}

@media only screen and (min-width: 280px) and (max-width:320px) {

  .sign-in {
    display: none;
  }

  .sign-in-bg {
    height: 100vh;
    padding-top: 0vh;
  }

  .sign-up {
    display: none;
  }

  .sign-up-bg {
    padding-bottom: 3rem;
  }

  .forgot {
    display: none;
  }

  .forgot-bg {
    height: 100vh;
    padding-top: 15vh;
  }

  .recover {
    display: none;
  }
  .recover-bg{
    height: auto;
    padding-top: 0vh;
  }
  .confirm{
    display: none;
  }
  .confirm-bg{
    height: 100vh;
    padding-top: 15vh;
  }
  .grp-card-head{
    height: 7rem;
  }
}

@media only screen and (min-width: 320px) and (max-width:377px) {

  .sign-in {
    display: none;
  }

  .sign-in-bg {
    height: 100vh;
    padding-top: 10vh;
  }

  .sign-up {
    display: none;
  }

  .sign-up-bg {
    padding-bottom: 3rem;
  }

  .forgot {
    display: none;
  }

  .forgot-bg {
    height: 100vh;
    padding-top: 15vh;
  }
  .recover {
    display: none;
  }
  .confirm{
    display: none;
  }
  .confirm-bg{
    height: 100vh;
    padding-top: 15vh;
  }
  .grp-card-head{
    height: 7rem;
  }
}

@media only screen and (min-width:377px) and (max-width: 414px) {
  .sign-in {
    display: none;
  }

  .sign-in-bg {
    height: 100vh;
    padding-top: 15vh;
  }

  .sign-up {
    display: none;
  }

  .sign-up-bg {
    padding-bottom: 3rem;
  }

  .forgot {
    display: none;
  }

  .forgot-bg {
    height: 100vh;
    padding-top: 24vh;
  }

  .recover {
    display: none;
  }

  .recover-bg {
    padding-top: 10vh;
  }
  .confirm{
    display: none;
  }
  .confirm-bg{
    height: 100vh;
    padding-top: 24vh;
  }
  .grp-card-head{
    height: 7rem;
  }
}

@media only screen and (min-width:414px) and (max-width: 480px) {
  .sign-in {
    display: none;
  }

  .sign-in-bg {
    height: 100vh;
    padding-top: 8vh;
  }

  .sign-up {
    display: none;
  }

  .sign-up-bg {
    padding-bottom: 3rem;
  }

  .forgot {
    display: none;
  }

  .forgot-bg {
    height: 100vh;
    padding-top: 16vh;
  }

  .recover {
    display: none;
  }

  .recover-bg {
    padding-top:0vh;
  }
  .confirm{
    display: none;
  }
  .confirm-bg{
    height: 100vh;
    padding-top: 16vh;
  }
  .grp-card-head{
    height: 7rem;
  }
}


@media only screen and (min-width:480px) and (max-width: 540px) {
  .sign-in {
    display: none;
  }

  .sign-in-bg {
    height: 100vh;
    padding-top: 15vh;
  }

  .sign-up {
    display: none;
  }

  .sign-up-bg {
    padding-bottom: 3rem;
  }

  .forgot {
    display: none;
  }

  .forgot-bg {
    height: 100vh;
    padding-top: 24vh;
  }

  .recover {
    display: none;
  }

  .recover-bg {
    padding-top: 10vh;
  }
  .confirm{
    display: none;
  }
  .confirm-bg{
    height: 100vh;
    padding-top: 24vh;
  }
  .grp-card-head{
    height: 5rem;
  }
}
// =========================================

// ======== media-query-for-medium-screens ========
@media only screen and (min-width:540px) and (max-width: 712px) {
  .sign-in {
    display: none;
  }

  .sign-in-bg {
    height: 100vh;
    padding-top: 13vh;
  }

  .sign-up {
    display: none;
  }

  .sign-up-bg {
    padding-bottom: 4rem;
  }

  .forgot {
    display: none;
  }

  .forgot-bg {
    height: 100vh;
    padding-top: 20vh;
  }

  .recover {
    display: none;
  }

  .recover-bg {
    height: 100vh;
    padding-top: 2rem;
  }
  .confirm{
    display: none;
  }
  .confirm-bg{
    height: 100vh;
    padding-top: 20vh;
  }
}

@media only screen and (min-width:712px) and (max-width: 768px) {
  .sign-in {
    display: none;
  }

  .sign-in-bg {
    height: 100vh;
    padding-top: 25vh;
    padding-left: 5vh;
    padding-right: 5vh;
  }

  .sign-up {
    display: none;
  }

  .sign-up-bg {
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .forgot {
    display: none;
  }

  .forgot-bg {
    height: 100vh;
    padding-top: 30vh;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .recover {
    display: none;
  }

  .recover-bg {
    height: 100vh;
    padding-top: 14rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .confirm{
    display: none;
  }
  .confirm-bg{
    height: 100vh;
    padding-top: 30vh;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (min-width:768px) and (max-width: 820px) {
  .sign-in {
    display: none;
  }

  .sign-in-bg {
    width: 100%;
    height: 100vh;
    padding-top: 24vh;
    padding-left: 5vh;
    padding-right: 5vh;
  }

  .sign-up {
    display: none;
  }

  .sign-up-bg {
    width: 100%;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .forgot {
    display: none;
  }

  .forgot-bg {
    width: 100%;
    height: 100vh;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 30vh;
  }

  .recover {
    display: none;
  }

  .recover-bg {
    width: 100%;
    height: 100vh;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 10rem;
  }
  .confirm{
    display: none;
  }
  .confirm-bg{
    width: 100%;
    height: 100vh;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 30vh;
  }
}

@media only screen and (min-width:820px) and (max-width: 912px) {
  .sign-in {
    display: none;
  }

  .sign-in-bg {
    height: 100vh;
    width: 100%;
    padding-top: 25vh;
    padding-left: 7vh;
    padding-right: 7vh;
  }

  .sign-up {
    display: none;
  }

  .sign-up-bg {
    width: 100%;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 6vh;
  }

  .forgot {
    display: none;
  }

  .forgot-bg {
    width: 100%;
    height: 100vh;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 30vh;
  }

  .recover {
    display: none;
  }

  .recover-bg {
    width: 100%;
    height: 100vh;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 15rem;
  }
  .confirm{
    display: none;
  }
  .confirm-bg{
    width: 100%;
    height: 100vh;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 30vh;
  }
}

@media only screen and (min-width:912px) and (max-width: 1024px) {
  .sign-in {
    display: none;
  }

  .sign-in-bg {
    height: 100vh;
    width: 100%;
    padding-top: 25vh;
    padding-left: 7vh;
    padding-right: 7vh;
  }

  .sign-up {
    display: none;
  }

  .sign-up-bg {
    width: 100%;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 10vh;
  }

  .forgot {
    display: none;
  }

  .forgot-bg {
    width: 100%;
    height: 100vh;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 30vh;
  }

  .recover {
    display: none;
  }

  .recover-bg {
    width: 100%;
    height: 100vh;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 15rem;
  }
  .confirm{
    display: none;
  }
  .confirm-bg{
    width: 100%;
    height: 100vh;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 30vh;
  }
}

 @media only screen and (min-height:1366px) and (max-height:1370px) {
  .sign-in {
    display: none;
  }

  .sign-in-bg {
    height: 100vh;
    width: 100%;
    padding-top: 25vh;
    padding-left: 8vh;
    padding-right: 8vh;
  }

  .sign-up {
    display: none;
  }

  .sign-up-bg {
    width: 100%;
    padding-left: 8rem;
    padding-right: 8rem;
    padding-top: 10vh;
  }

  .forgot {
    display: none;
  }

  .forgot-bg {
    width: 100%;
    height: 100vh;
    padding-left: 8rem;
    padding-right: 8rem;
    padding-top: 30vh;
  }

  .recover {
    display: none;
  }

  .recover-bg {
    width: 100%;
    height: 100vh;
    padding-left: 8rem;
    padding-right: 8rem;
    padding-top: 21rem;
  }
  .confirm{
    display: none;
  }
  .confirm-bg{
    width: 100%;
    height: 100vh;
    padding-left: 8rem;
    padding-right: 8rem;
    padding-top: 30vh;
  }
}

//======= media-query-for-desktop ================
@media only screen and (min-width: 1280px) and (min-height: 800px) {
  .sign-in-bg {
    padding-top: 15vh;
  }

  .forgot-bg {
    padding-top: 21vh;
  }

  .recover-bg {
    padding-top: 7vh;
  }
  .confirm-bg{
    padding-top: 21vh;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1440px) {
  .sign-in-bg {
    padding-top: 15vh;
  }

  .forgot-bg {
    padding-top: 21vh;
  }

  .recover-bg {
    padding-top: 7vh;
  }
  .confirm-bg{
    padding-top: 21vh;
  }
  
}

@media only screen and (min-width: 1440px) and (max-width: 1920px) {
  .sign-in-bg {
    padding-top: 15vh;
  }

  .forgot-bg {
    padding-top: 21vh;
  }

  .recover-bg {
    padding-top: 7vh;
  }
  .confirm-bg{
    padding-top: 21vh;
  }
}

@media only screen and (min-width: 1920px) and (max-width: 2160px) {
  .sign-in-bg {
    padding-top: 23vh;
  }

  .forgot-bg {
    padding-top: 27vh;
  }

  .recover-bg {
    padding-top: 16vh;
  }

  .sign-up-bg {
    padding-top: 7vh;
  }
  .confirm-bg{
    padding-top: 27vh;
  }
}

@media only screen and (min-width: 2160px) and (max-width: 2304px) {
  .sign-in-bg {
    padding-top: 23vh;
  }

  .forgot-bg {
    padding-top: 27vh;
  }

  .recover-bg {
    padding-top: 16vh;
  }

  .sign-up-bg {
    padding-top: 20vh;
  }
  .confirm-bg{
    padding-top: 27vh;
  }
}

@media only screen and (min-width: 2304px) and (max-width: 2560px) {
  .sign-in-bg {
    padding-top: 28vh;
  }

  .forgot-bg {
    padding-top: 32vh;
  }

  .recover-bg {
    padding-top: 27vh;
  }

  .sign-up-bg {
    padding-top: 14vh;
  }
  .confirm-bg{
    padding-top: 32vh;
  }
}

@media only screen and (min-width: 2560px) and (max-width: 2880px) {
  .sign-in-bg {
    padding-top: 28vh;
  }

  .forgot-bg {
    padding-top: 32vh;
  }

  .recover-bg {
    padding-top: 27vh;
  }

  .sign-up-bg {
    padding-top: 18vh;
  }
  .confirm-bg{
    padding-top: 32vh;
  }
}

@media only screen and (min-width: 2880px) and (max-width: 3840px) {
  .sign-in-bg {
    padding-top: 31vh;
  }

  .forgot-bg {
    padding-top: 32vh;
  }

  .recover-bg {
    padding-top: 27vh;
  }

  .sign-up-bg {
    padding-top: 20vh;
  }
  .confirm-bg{
    padding-top: 32vh;
  }
}

@media only screen and (min-width: 3840px) and (max-width: 4096px) {
  .sign-in-bg {
    padding-top: 34vh;
  }

  .forgot-bg {
    padding-top: 35vh;
  }

  .recover-bg {
    padding-top: 32vh;
  }

  .sign-up-bg {
    padding-top: 20vh;
  }
  .confirm-bg{
    padding-top: 35vh;
  }
}

@media only screen and (min-width: 4096px) and (max-width: 5120px) {
  .sign-in-bg {
    padding-top: 34vh;
  }

  .forgot-bg {
    padding-top: 35vh;
  }

  .recover-bg {
    padding-top: 32vh;
  }

  .sign-up-bg {
    padding-top: 20vh;
  }
  .confirm-bg{
    padding-top: 35vh;
  }
}

@media only screen and (min-width: 5120px) {
  .sign-in-bg {
    padding-top: 34vh;
  }

  .forgot-bg {
    padding-top: 35vh;
  }

  .recover-bg {
    padding-top: 32vh;
  }

  .sign-up-bg {
    padding-top: 30vh;
  }
  .confirm-bg{
    padding-top: 35vh;
  }
}

