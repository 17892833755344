.Demo__container {
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .Demo__some-network {
    text-align: center;
  }
  
  .Demo__some-network__share-count {
    display: inline-flex;
    justify-content: center;
    white-space: nowrap;
    overflow: visible;
    width: 0;
    font-size: 14px;
  }
  
  .Demo__some-network__share-button {
    cursor: pointer;
  }
  
  .Demo__some-network__share-button:hover:not(:active) {
    opacity: 0.75;
  }
  