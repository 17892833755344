@each $color in $colors-name {
    .btn{
        &.btn-#{$color}{
            color: var(--#{$variable-prefix}white);
             background-color: #173F5F;
            // background-color: var(--#{$variable-prefix}#{$color});
            border-color: #173F5F;
            // border-color: var(--#{$variable-prefix}#{$color});
            
            &:hover{
                color:  var(--#{$variable-prefix}white);
                background-color: var(--#{$variable-prefix}#{$color}-shade-20);
                border-color: var(--#{$variable-prefix}#{$color}-shade-20);
            }
            &:focus{
                color: var(--#{$variable-prefix}white);
                background-color: var(--#{$variable-prefix}#{$color}-shade-20);
                border-color: var(--#{$variable-prefix}#{$color}-shade-20);
            }
            &:active, &.active{
                color: var(--#{$variable-prefix}white);
                background-color: var(--#{$variable-prefix}#{$color}-shade-20);
                border-color: var(--#{$variable-prefix}#{$color}-shade-20);
            }
        }
        &.btn-outline-#{$color}{
            color: var(--#{$variable-prefix}#{$color}-shade-20);
            border-color: var(--#{$variable-prefix}#{$color}-shade-20);
            &:hover{
                color:  var(--#{$variable-prefix}white);
                background-color: var(--#{$variable-prefix}#{$color});
                border-color: var(--#{$variable-prefix}#{$color});
            }
            &:active, &.active{
                color: var(--#{$variable-prefix}white);
                background-color: var(--#{$variable-prefix}#{$color}-shade-20);
                border-color: var(--#{$variable-prefix}#{$color}-shade-20);
            }
        }
        &.btn-soft-#{$color}{
            color: var(--#{$variable-prefix}#{$color});
            background-color: rgba(var(--#{$variable-prefix}#{$color}-rgb), .2);
            border-color: transparent;
            &:hover, &:focus, &:active{
                color: var(--#{$variable-prefix}#{$color});
                background-color: rgba(var(--#{$variable-prefix}#{$color}-rgb), .4);
                border-color: transparent;
            }
        }
    }
}

.btn-link{
    color:var(--#{$variable-prefix}primary);
    &:hover{
        color: var(--#{$variable-prefix}primary-tint-20);
    }
}
.bd-aside{
    .active{
        color: var(--#{$variable-prefix}primary);
        background-color: #173F5F;
        // background-color: var(--#{$variable-prefix}primary-tint-90);
    }
    .btn:hover{
        color: var(--#{$variable-prefix}primary);
        background-color: #173F5F;
        // background-color: var(--#{$variable-prefix}primary-tint-90);
    }
    .btn:focus{
        color: var(--#{$variable-prefix}primary);
        background-color: #173F5F;
        // background-color: var(--#{$variable-prefix}primary-tint-90);
    }
    a:hover{
        background-color: #173F5F;
        // background-color: var(--#{$variable-prefix}primary-tint-88) ;
    }
}

@media only screen and (min-width: 280px) and (max-width:320px){
    .grp-btn {
        position: absolute;
        top: 4rem;
        left: 1rem;
        font-size: 12px;
        padding: 5px;
    }
        }

@media only screen and (min-width: 320px) and (max-width:360px){
    .grp-btn {
        position: absolute;
        top: 4rem;
        left: 1rem;
        font-size: 12px;
        padding: 5px;
    }
        }

@media only screen and (min-width: 360px) and (max-width:375px){
    .grp-btn {
        position: absolute;
        top: 4rem;
        left: 1rem;
        font-size: 12px;
        padding: 5px;
    }
        }

@media only screen and (min-width: 375px) and (max-width:390px){
    .grp-btn {
        position: absolute;
        top: 4rem;
        left: 1rem;
        font-size: 12px;
        padding: 5px;
    }
        }

@media only screen and (min-width: 390px) and (max-width:393px){
    .grp-btn {
        position: absolute;
        top: 4rem;
        left: 1rem;
        font-size: 12px;
        padding: 5px;
    }
        }

@media only screen and (min-width: 393px) and (max-width:414px){
    .grp-btn {
        position: absolute;
        top: 4rem;
        left: 1rem;
        font-size: 12px;
        padding: 5px;
    }
        }

@media only screen and (min-width: 414px) and (max-width:480px){
.grp-btn {
    position: absolute;
    top: 4rem;
    left: 1rem;
    font-size: 12px;
    padding: 5px;
}
    }

    @media only screen and (min-width: 480px) and (max-width:540px){
        .grp-btn {
            position: absolute;
            top: 1.5rem;
            left: 8.5rem;
            font-size: 12px;
            padding: 5px;
        }
            }
        

    