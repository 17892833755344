/* sign-up */

.text {
  color: #173f5f;
}
.btn-primary {
  background-color: #173f5f;
}

.errortext {
  color: red;
}
.sign-in-page {
  label {
    font-weight: bold;
  }
  .mandatory {
    color: red;
  }
}
// Font Formatting
.fontBold {
  font-weight: 600;
}
.textBlue {
  color: #173f5f;
}

// Avator Icon
.avatar-50 {
  border: solid 1px #00000024;
}
.listItems {
  font-size: 1.2rem;
  line-height: 2rem;
}
// Group List CSS
.group-info {
  h4.groupName {
    a {
      font-weight: 600;
      color: #000 !important;
    }
  }
  p {
    margin-bottom: 0;
    color: #3fa595;
    font-weight: bold;
  }
}

.group-details {
  li {
    p {
      text-transform: capitalize;
      font-weight: 600;
      color: #1c6199;
    }
    h5 {
      font-weight: 600;
      font-size: 0.85rem;
    }
    h6 {
      font-weight: 600;
    }
  }
}
.grp-text {
  font-weight: bold;
  font-size: 1.5rem;
  color: #1e629a;
}
// Group Detailed  CSS
.groupName {
  font-weight: 600;
  color: #000 !important;
}
@media only screen and (max-width: 425px) {
  .groupButtons {
    margin-top: 15px;
    width: 100%;
    justify-content: center;
  }
  .grp-text {
    margin-bottom: 10px;
    font-size: 1rem;
  }
  .d-grid-template-19rem-19rem {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 20rem)) !important;
}

}

// profile Css
.profile-detail h3 {
  font-weight: 600;
  color: #000 !important;
  margin-top: 10px;
}
.card-title {
  font-size: 14px;
  line-height: 18px;
  color: #000;
}
.user-tabing .nav-link {
  color: #1f639c !important;
  text-align: center;

  font-weight: 600;
  font-size: 1rem;
}
.user-tabing .nav-link.active {
  color: #fff !important;
}
.comment-data-block h6 {
  font-weight: 600;
  color: #000;
}

.comment-data-block  {
max-width: 90%;
}

.comment-data-block span {
  font-size: 12px;
  color: #1f639c;
}
.sidebar-mini .sub-nav .sidebar-label,
.sidebar-mini .sub-nav .sidebar-label i {
  font-size: 19px;
}
.scrollInner {
  justify-content: flex-start !important;
  max-height: 100vh !important;
  overflow: scroll !important;
}
.signUpButtonNew {
  display: flex;
  flex-direction: column;
  align-items: center;
  .buttonLink {
    display: inline-block;
    background: #4dac9e;
    width: 121px;
    padding: 3px 7px;
    border-radius: 5px;
    font-weight: 500;
    color: #fff;
    font-size: 17px;
  }
}
.offcanvas.show {
  transform: none;
  width: 250px;
  box-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.075);
  border: none;
  .navbar-list li > a {
    font-size: 0.95rem;
    padding: 0px;
  }
}
.notification-list {
  h6 {
    font-size: 0.875rem;
    color: #000;
    line-height: 16px;
    margin-bottom: 12px;
    font-weight: 600;
  }
  p {
    color: #1f639c;
  }
}
