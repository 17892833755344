.form-check-input{
    &:checked{
        border-color: #173F5F;
        background-color: #173F5F;
    }
    &:focus{
        border-color: var(--#{$variable-prefix}primary-shade-20);
    }
}

@each $color in $colors-name {
    .border-#{$color}{
        border-color: var(--#{$variable-prefix}#{$color}) !important;
    }
}