@each $color in $colors-name {
    .text-#{$color}{
        color: var(--#{$variable-prefix}#{$color}) !important;
    }
    .bg-#{$color}{
        background-color: var(--#{$variable-prefix}#{$color}) !important;
    }
    .bg-soft-#{$color}{
        color: var(--#{$variable-prefix}#{$color});
        background-color: rgba(var(--#{$variable-prefix}#{$color}-rgb), .1) !important;
    }
    a, button{
        &.bg-soft-#{$color}{
            color: var(--#{$variable-prefix}#{$color});
            background-color: rgba(var(--#{$variable-prefix}#{$color}-rgb), .1) !important;
            &:hover, &:focus{
                color: var(--#{$variable-prefix}#{$color}-shade-20);
                background-color: rgba(var(--#{$variable-prefix}#{$color}-rgb), .2) !important;
            }
        }
    }
}

// @media only screen and (max-width: 414px) {
// .connection-input{
//     width: 10rem;
// }
// }

@media only screen and (max-width: 414px){
    .banner{
        width: 100px;
        height: 50px;
    }
    p, .scrtDescription {
        font-size: 13px;
        line-height: 16px;
    }
    .scrtTitle{font-size: 14px;
        line-height: 17px;}
    }

    @media only screen and (min-width: 480px) and (max-width: 540px){
        .banner-img{
            width: 150px;
            height: auto;
        }
    }



    //=============

    @media only screen and (min-width: 280px) and (max-width: 320px){
        .connection-input{
            width: 5rem;
            padding-right: 21px;
        }
        
    }

    @media only screen and (min-width: 320px) and (max-width: 360px){
        .connection-input{
            width: 7rem;
            padding-right: 21px;
        }
        
    }
    
    @media only screen and (min-width: 360px) and (max-width: 375px){
        .connection-input{
            width: 10rem;
        }
        
    }
    
    @media only screen and (min-width: 375px) and (max-width: 390px){
        .connection-input{
            width: 10rem;
        }
        
    }
    
    @media only screen and (min-width: 390px) and (max-width: 393px){
        .connection-input{
            width: 10rem;
        }
    }
    
    @media only screen and (min-width: 393px) and (max-width: 414px){
        .connection-input{
            width: 10rem;
        }
    }
    
    @media only screen and (min-width: 414px) and (max-width: 480px){
        .connection-input{
            width: 10rem;
        }
        
    }